import { useLocation } from "@reach/router";

/**
 * Parses the query parameters to return a single param
 * value.
 */
export const useQuery = (queryParam: string) => {
  const search = new URLSearchParams(useLocation().search);
  return search.get(queryParam);
};

/**
 * @returns an instance of URLSearchParams containing all search
 * params in the given uri
 */
export const useSearchParams = () => {
  return new URLSearchParams(useLocation().search);
};
